import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import Container from "../components/ui/Container";
import {
  VStack,
  Text,
  Grid,
  GridItem,
  Box,
  Stack,
  Button,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import Carousel from "components/ui/Carousel";
import Features from "components/Features";
import Reviews from "components/Reviews";
import Partners from "components/Partners";

const IndexPage = () => (
  <Box>
    <Seo
      keywords="interior design software, interior design rendering software, ai interior design tool, online floor plan design software, cloud-based interior design and rendering software, ai interior design software, interior design software free, Ai kitchen design software, 3d interior design online, Modern house design software, ai tool for interior design, Ai 3D Interior Design Software"
      description="3D Interior Design & Render Software for interior designers with an immersive experience. Welcome to our 3D Interior Design Online platform, sign up for the Ai interior design software."
    />

    <Box
      backgroundImage={require("../images/banners/cover-banner.jpg").default}
      backgroundSize="cover"
      backgroundPosition="center"
      py={20}
    >
      <Container fluid>
        <VStack
          alignItems="flex-start"
          color="#fff"
          spacing={4}
          maxW={{
            base: "100%",
            lg: "75%",
          }}
        >
          <Text
            fontWeight="bold"
            fontSize={{
              base: "2xl",
              lg: "4xl",
            }}
          // lineHeight={1}
          >
            One Online Platform To Design Every Space
            {/* Experience the New Era of AiHouse Advanced 4D Design */}
            {/* Intelligent Cloud Design Platform */}
          </Text>
          <Text
            fontWeight="bold"
            fontSize={{
              base: "2xl",
              lg: "4xl",
            }}
          // lineHeight={1}
          >
            Next-Gen AI-Powered 3D Home Design Software
            {/* Discover the Latest Version & Enhancements */}
            {/* Unlock your 3D potential */}
          </Text>
          <Text fontSize={{ base: "lg", lg: "xl" }} lineHeight={1}>
            Extensive customizations | Modern gallery feature | Stunning 16K
            renders
          </Text>

          <Button
            as="a"
            href="https://graph-new.aihouse.com/reg?owner=in"
            target="_blank"
            rel="noreferrer noopener"
            size="md"
            colorScheme="orange"
            textTransform="capitalize"
          >
            Get a Trial Version
            {/* Try now for free */}
          </Button>
        </VStack>
      </Container>
    </Box>

    <Container fluid mt={10}>
      <Features />
    </Container>

    <Box backgroundColor="#E0DADA" paddingBlock={16} marginTop={10}>
      <Container fluid>
        <VStack spacing={6}>
          <Text fontSize="4xl" fontWeight="medium" textAlign="center" as="h1">
            3D Interior Design Rendering And Visualization Software
          </Text>

          <VStack textAlign="center" maxW="4xl" spacing={3}>
            <Text>
              AiHouse is a user-friendly, intelligent, cloud-based interior
              design and rendering platform, it's an Ai tool for interior design which means that you can be working
              lightning-fast speed and work on your designs without any lag or delays.
              Where you can quickly create smart 3D designs and renderings to showcase to end-users/clients.
            </Text>

            <Text>
              Whether you want to floor plans design software, interior layouts,
              furniture, fixtures, and decorative elements, all that can now be
              done in a matter of minutes in an intuitive, seamless process. At
              the starting point, you have 4 ways to design entire complete
              floorplans &#8212; select from the library, free drawing mode,
              importing/uploading from CAD software files & PNG/JPEG images.
            </Text>

            <Text>
              Get started today and take your interior design to the next level with our
              3D Interior Design Software, and see for yourself what makes it the best in the business!
            </Text>

            <Text>
              To experience the Ai interior design tool, you can simply select from the vast
              library (80 million + materials and models), Online Interior Rendering, drag and drop into
              your floor plans and then, customize per your wish and
              requirements.
            </Text>
          </VStack>

          <Stack spacing={4} direction={{ base: "column", md: "row" }}>
            {/* <Button
              size-="lg"
              colorScheme="orange"
              variant="outline"
              as={Link}
              to="/contact-us/"
              rounded="full"
              textTransform="uppercase"
            >
              Book a demo
            </Button> */}

            <Button
              size-="lg"
              colorScheme="orange"
              variant="outline"
              as="a"
              href="https://graph-new.aihouse.com/reg?owner=in"
              target="_blank"
              rel="noreferrer noopener"
              rounded="full"
              textTransform="uppercase"
            >
              Get a Free Starter Trial
            </Button>
          </Stack>
        </VStack>
      </Container>
    </Box>

    <Container fluid mt={10}>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
        templateRows={{ base: "auto", lg: "repeat(2, 1fr)" }}
        gap={10}
      >
        <GridItem>
          <VStack spacing={6} alignItems="flex-start">
            <Text fontSize="3xl">Design and Render</Text>

            <Text fontSize="lg" fontWeight="light">
              Easily plan your space and customize the details. Quickly impress
              clients via 4K visualizations.
            </Text>

            <div className="w-36 h-0.5 bg-yellow-500" />
          </VStack>
        </GridItem>

        <GridItem
          rowSpan={{ base: 1, lg: 2 }}
          rowStart={1}
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <VStack spacing={6}>
            <StaticImage
              src="../images/ui/function-experience.png"
              alt="function experience icon aihouse india"
            />

            <Box backgroundColor="#FEE9D2" rounded="full" px={10} py={4}>
              Functions + Experience
            </Box>
          </VStack>
        </GridItem>


        <GridItem>
          <VStack spacing={6} alignItems="flex-start">
            <Text fontSize="3xl">Animations</Text>

            <Text fontSize="lg" fontWeight="light">
              Produce mind-blowing animated walk-throughs powered by real-time
              ray tracing technology.
            </Text>

            <div className="w-36 h-0.5 bg-yellow-500" />
          </VStack>
        </GridItem>

        <GridItem>
          <VStack spacing={6} alignItems="flex-start">
            <Text fontSize="3xl">Brand Gallery</Text>

            <Text fontSize="lg" fontWeight="light">
              Expand your brand visibility by showcasing your products in our
              library.
            </Text>

            <div className="w-36 h-0.5 bg-yellow-500" />
          </VStack>
        </GridItem>
      </Grid>
    </Container>

    <Box backgroundColor="#FFFBF6" paddingBlock={16} marginTop={10}>
      <Container fluid>
        <VStack spacing={10}>
          <Text fontSize="4xl" textAlign="center">
            Unlock your 3D potential
          </Text>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap={6}
          >
            <GridItem>
              <VStack spacing={4}>
                <StaticImage
                  src="../images/ui/interior-designer.jpg"
                  alt="interior designer icon aihouse india"
                />

                <VStack spacing={1} w="80%">
                  <Text fontSize="2xl" textAlign="center">
                    Interior Designer
                  </Text>

                  <Text textAlign="center" fontWeight="light">
                    Drag and drop. Edit the details. Render in minutes
                  </Text>
                </VStack>
              </VStack>
            </GridItem>

            <GridItem>
              <VStack spacing={4}>
                <StaticImage
                  src="../images/ui/furniture-brand.jpg"
                  alt="furniture brand icon aihouse india"
                />

                <VStack spacing={2} w="80%">
                  <Text fontSize="2xl" textAlign="center">
                    Furniture Brand
                  </Text>

                  <Text textAlign="center" fontWeight="light">
                    Drive sales with refreshing marketing visuals
                  </Text>
                </VStack>
              </VStack>
            </GridItem>

          </Grid>
        </VStack>
      </Container>
    </Box>

    <Box backgroundColor="#FFDFC0" paddingBlock={16} marginTop={10}>
      <Container fluid>
        <VStack spacing={6}>
          <Text fontSize="4xl" fontWeight="medium" textAlign="center" as="h1">
            Ai Tool For Interior Design
          </Text>

          <VStack textAlign="center" maxW="4xl" spacing={3}>
            <Text>
              Looking for the best AI tool for interior design?
              Try AiHouse India's software, offering free and online 3D interior design capabilities.
              Explore the top-rated Online 3D Interior Design Software for all your interior designing needs.
              With our Ai interior design software and tool, you can bring your creative vision to life with ease.

            </Text>

            <Text>
              Experience the power of our online interior render software,
              allowing you to visualize your designs in realistic 3D.
              Discover the best interior design software that professionals and enthusiasts alike are raving about.
              One of the standout features of AiHouse India it's useful to make Ai-generated interior design.
              giving them a true-to-life representation of their future space.
              This recommended kitchen interior design software to create your dream space.
              From designing layouts to selecting color schemes and materials.

            </Text>

            <Text>
              Get a free sign-up today to experience its groundbreaking features,
              and elevate your interior design game to unprecedented heights.
            </Text>

          </VStack>
        </VStack>
      </Container>
    </Box>

    <VStack spacing={10} marginTop={10} className="overflow-hidden">
      <Container fluid>
        <Text fontSize="4xl" textAlign="center">
          Explore our users’ work. Try AiHouse for yourself.
        </Text>
      </Container>

      <Carousel
        infinite
        dots={false}
        slidesToScroll={1}
        responsive={[
          {
            breakpoint: 10000,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 2048,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
            },
          },
        ]}
      >
        <Box p={2}>
          <StaticImage src="../images/banners/work-1.jpg" alt="work-1" />
        </Box>
        <Box p={2}>
          <StaticImage src="../images/banners/work-2.jpg" alt="work-2" />
        </Box>
        <Box p={2}>
          <StaticImage src="../images/banners/work-3.jpg" alt="work-3" />
        </Box>
        <Box p={2}>
          <StaticImage src="../images/banners/work-4.jpg" alt="work-4" />
        </Box>
        <Box p={2}>
          <StaticImage src="../images/banners/work-5.jpg" alt="work-5" />
        </Box>
        <Box p={2}>
          <StaticImage src="../images/banners/work-6.jpg" alt="work-6" />
        </Box>
        <Box p={2}>
          <StaticImage src="../images/banners/work-7.jpg" alt="work-7" />
        </Box>
      </Carousel>
    </VStack>

    <Container fluid mt={10}>
      <Reviews />
    </Container>

    <Container fluid mt={10}>
      <Partners />
    </Container>

    <Box marginTop={10} backgroundColor="#ffd6c1" p={6}>
      <Container fluid>
        <Stack
          spacing={4}
          justifyContent="center"
          alignItems="center"
          direction={{ base: "column", md: "row" }}
        >
          <Text fontSize="3xl" textAlign={{ base: "center", md: "left" }}>
            We provide AiHouse Software Training Videos
          </Text>
          <Button
            colorScheme="red"
            rightIcon={<DownloadIcon />}
            variant="outline"
            as={Link}
            to="/contact-us/?action=download_brochure"
          >
            Download Brochure
          </Button>
        </Stack>
      </Container>
    </Box>
  </Box>
);

export default IndexPage;
